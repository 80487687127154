<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="name-your-emotions.png">
    <template v-slot:title> Name your emotions</template>
    <template v-slot:subtitle>
      Different people have different experiences of suicidal feelings.
      Sometimes it is difficulty identifying and labeling your emotions. When
      you are aware of your emotion, you are able to pay attention to how you
      are coping.
    </template>
    <template v-slot:description>
      Here are some ways to help you recognize and label your emotions.
    </template>
  </Banner>

  <section class="pb-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        How are you feeling right now?
      </div>
      <p>
        Take a moment to connect to your current emotional experience. Now,
        focus your attention on what you are feeling; there might be feelings of
        hopelessness, loneliness, anger, or sadness. If you are feeling
        suicidal, you might be scared or confused by these feelings. You may
        find the feelings overwhelming. This worksheet is designed to help you
        recognizing and labeling your emotions.
      </p>
      <p class="my-6 text-lg text-blue md:text-xl">
        Look at the word list below and use it as a guide to help you label the
        emotions you are experiencing.
      </p>
    </div>
  </section>
  <!-- List of emotions  -->
  <section class="pb-10 md:pb-14">
    <div
      class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
    >
      List of emotions:
    </div>
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="grid grid-cols-1 gap-3 md:grid-cols-3">
        <a href="javascript:;" class="emotion">Confusion</a>
        <a href="javascript:;" class="emotion">Powerlessness</a>
        <a href="javascript:;" class="emotion">Inferior</a>
        <a href="javascript:;" class="emotion">Sad</a>
        <a href="javascript:;" class="emotion">Depressed</a>
        <a href="javascript:;" class="emotion">Worried</a>
        <a href="javascript:;" class="emotion">Numb</a>
        <a href="javascript:;" class="emotion">Helplessness</a>
        <a href="javascript:;" class="emotion">Ashamed</a>
        <a href="javascript:;" class="emotion">Loneliness</a>
        <a href="javascript:;" class="emotion">Isolated</a>
        <a href="javascript:;" class="emotion">Rejected</a>
        <a href="javascript:;" class="emotion">Fear</a>
        <a href="javascript:;" class="emotion">Discouraged</a>
        <a href="javascript:;" class="emotion">Grief</a>
        <a href="javascript:;" class="emotion">Insecure</a>
        <a href="javascript:;" class="emotion">Guilt</a>
        <a href="javascript:;" class="emotion">Despair</a>
        <a href="javascript:;" class="emotion">Overwhelmed</a>
        <a href="javascript:;" class="emotion">Insecure</a>
        <a href="javascript:;" class="emotion">Inadequate</a>
        <a href="javascript:;" class="emotion">Abandoned</a>
        <a href="javascript:;" class="emotion">Vulnerable</a>
        <a href="javascript:;" class="emotion">Victimized</a>
        <a href="javascript:;" class="emotion">Empty</a>
        <a href="javascript:;" class="emotion">Worthless</a>
        <a href="javascript:;" class="emotion">Embarrassed</a>
        <a href="javascript:;" class="emotion">Frightened</a>
        <a href="javascript:;" class="emotion">Threatened</a>
        <a href="javascript:;" class="emotion">Exposed</a>
        <a href="javascript:;" class="emotion">Excluded</a>
        <a href="javascript:;" class="emotion">Scared</a>
        <a href="javascript:;" class="emotion">Desperate</a>
        <a href="javascript:;" class="emotion">Humiliated</a>
        <a href="javascript:;" class="emotion">Betrayed</a>
        <a href="javascript:;" class="emotion">Let down</a>
        <a href="javascript:;" class="emotion">Violated</a>
        <a href="javascript:;" class="emotion">Ridiculed</a>
        <a href="javascript:;" class="emotion">Stressed</a>
      </div>
    </div>
  </section>
  <!-- List of Emotions image  -->
  <section class="py-10 md:py-14 emotion-bg">
    <div class="emj-img">
      <img src="../../assets/images/notebook.png" class="mx-auto" />
    </div>
  </section>

  <!-- JOURNAL -->
  <section class="py-10 my-8 md:my-12 md:py-14">
    <div class="max-w-6xl p-4 mx-auto bg-blue-100 sm:px-6">
      <div class="items-center md:flex">
        <div class="w-52 safety-img md:pr-9">
          <img src="../../assets/images/name-your-emotions-yoga.png" />
        </div>
        <div class="s-div">
          <div class="mb-4 text-2xl font-bold text-blue">
            Guided feelings and thought journal
          </div>
          <p class="mb-5">
            Use this guided feelings and thought journal to work through your
            emotions, how you feel and what triggers you.
          </p>
          <a
            href="/files/thought-journal.pdf"
            download
            class="font-bold text-green hover:text-gray"
          >
            Download
            <svg
              class="inline-block"
              xmlns="http://www.w3.org/2000/svg"
              width="10.922"
              height="28"
              viewBox="0 0 10.922 28"
            >
              <path
                id="Path_4072"
                data-name="Path 4072"
                d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                transform="translate(-831.098 -480.029)"
                fill="currentColor"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>

  <FooterCard
    img="help-for-someone-else-braid.png"
    text="View Tools & resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>Try another coping strategy</template>
    <template v-slot:content>
      Different coping strategies resonate with different people.
      <br />
      Discover more coping strategies and add to your toolbox.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import FooterCard from "@/components/FooterCard.vue";

export default {
  name: "NameYourEmotions",
  components: {
    Banner,
    FooterCard,
  },
};
</script>

<style scoped>
.emotion {
  background: #f4f6fa;
  color: #3c5eaa;
  font-size: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.emotion-bg {
  background-image: url(../../assets/images/list-bg.jpg);
  background-size: cover;
  background-position: bottom center;
}

.emotion:hover {
  background: #3c5eaa;
  color: #fff;
}

.safety-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.s-div {
  width: calc(100% - 200px);
}
@media (max-width: 767px) {
  .safety-img {
    margin: 0 auto 25px;
  }
  .s-div {
    width: 100%;
  }
}
</style>
